@font-face {
  font-family: Copenhagen;
  src: url(assets/fonts/copenhagen-medium.woff);
}

@font-face {
  font-family: Apercu;
  src: url(assets/fonts/apercu-regular.ttf);
}

h1,
h2 {
  font-family: "Copenhagen";
  text-transform: uppercase;
}
h3,
h4 {
  font-family: "Apercu";
  font-weight: bold;
}
p {
  font-family: "Apercu";
  font-size: 14px;
}
a {
  text-decoration: none;
  color: black;
  font-family: "Apercu";
}
a:-webkit-any-link {
  color: black;
  font-family: "Apercu";
}
